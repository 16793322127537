<template>
  <v-card width="100%">
    <v-container>
      <v-card-title class="pl-1 primary--text"
        >Detalle del proceso de actualización de importes fijos
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="detallesAumentoImpFijos"
        class="elevation-1"
        :loading="isLoading"
      >
      </v-data-table>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-col cols="3" class="p-2">
          <v-tooltip left max-width="40%">
            <template v-slot:activator="{ attrs }">
              <v-btn
                color="primary"
                class="to-right fontsize"
                v-bind="attrs"
                outlined
                :disabled="canExport"
                @click="exportExcelModelo"
              >
                Exportar detalle
              </v-btn>
            </template>
          </v-tooltip>
        </v-col>
        <v-btn outlined @click="closeModalVerDetalle"> Cerrar </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>
<script>
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import helpersExcel from "@/utils/helpers/importExportExcel";

export default {
  name: "VerDetalleActualizacionImpFijos",
  props: {
    procesoId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    excelIcon: enums.icons.EXPORT_EXCEL,
    isLoading: false,
    detallesAumentoImpFijos: [],
    canExport: false,
    headers: [
      {
        text: "N° de grupo familiar",
        value: "benGrId",
        sortable: false,
      },
      {
        text: "Afiliado titular",
        value: "afiliadoTit",
        sortable: false,
      },
      {
        text: "Concepto",
        value: "concepto.value",
        sortable: false,
      },
      {
        text: "Período desde",
        value: "periodoDesde",
        sortable: false,
      },
      {
        text: "Período hasta",
        value: "periodoHasta",
        sortable: false,
      },
      {
        text: "Importe",
        value: "importeToShow",
        sortable: false,
        align: "right"
      },
    ]
  }),
  created() {
    this.getdetallesAumentoImpFijos();
  },
  methods: {
    ...mapActions({
      verDetalleImportesFijos: "devengamientos/verDetalleImportesFijos",
    }),
    async getdetallesAumentoImpFijos() {
      this.isLoading = true;
      const data = await this.verDetalleImportesFijos(this.procesoId);
      this.detallesAumentoImpFijos = data;
      if(this.detallesAumentoImpFijos.length == 0){
        this.canExport = true;
      }
      this.isLoading = false;
    },
    closeModalVerDetalle() {
      this.$emit("toggleModalSeeProcesoActualizacion");
    },
    exportExcelModelo() {
      let result = [];
      this.detallesAumentoImpFijos.forEach((x) =>
        result.push({
          ["Grupo familiar"]: x.benGrId,
          ["Afiliado titular"]: x.afiliadoTit,
          ["Concepto"]: x.concepto.value,
          ["Importe"]: x.importeToShow,
          ["Periodo desde"]: x.periodoDesde,
          ["Periodo hasta"]: x.periodoHasta,
        })
      );
      let formato = {
        filas: result,
        hoja: "Detalles de actualización",
      };
      helpersExcel.excelExport(formato, "Detalle de actualización");
    },
  },
};
</script>

<style lang="scss" scoped>
.fontsize {
  font-size: 12px;
}
</style>